.ff-primary {
  font-family: $primary-font-family !important;
}

.Helvetica-Neue-Regular {
  font-family: "Helvetica-Neue-Regular" !important;
}

.Helvetica-Neue-Medium {
  font-family: "Helvetica-Neue-Medium" !important;
}

.Helvetica-Neue-Bold {
  font-family: "Helvetica-Neue-Bold" !important;
}

