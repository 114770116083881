$root-font-size: 16;

@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

// Font Sizes
$i: 8;

@while $i<=166 {
  .fs-#{$i} {
    font-size: #{$i / $root-font-size}rem !important;
  }

  $i: $i + 1;
}
// font weight
@for $fw-index from 100 to 1000 {
  .fw-#{$fw-index} {
    font-weight: ($fw-index) !important;
  }
}

@mixin line-clamp($number) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: $number;
  -webkit-box-orient: vertical;
}
