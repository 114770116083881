.avatar-wrapper {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    overflow: hidden;

    .avatar-image {
      aspect-ratio: 1/1;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }