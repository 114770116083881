label {
  position: absolute;
  top: -10px;
  left: 0;
  margin: 0;
  transform-origin: 0 0;
  transform: translate3d(0.5rem, 1.36rem, 0);
  font-size: 16px;
  // font-weight: 500;
  font-family: "Helvetica-Neue-Regular";
  transition: transform 0.2s, color 0.2s ease-in-out;
  color: $gray-550;
  cursor: text;
    background-color: $white;
  padding: 0 0.5rem;
}

input:focus + label,
input:not(:placeholder-shown) + label {
  display: none; // transform: translate3d(0.5rem, 0rem, 0) scale(0.75);
}

svg.input-clear-btn {
  position: absolute;
  top: calc(50% - 0.5rem);
  right: 0px;
  transform: translate(4px, -50%);
  color: $gray-600;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s ease 0.2s, transform 0.2s ease, opacity 0.2s ease;
}

input:not(:placeholder-shown) + label + span.input-error + svg.input-clear-btn {
  opacity: 1;
  visibility: visible;
  transform: translate(-8px, -50%);
  transition: visibility 0s ease 0s, transform 0.2s ease, opacity 0.2s ease;
}

.search-input__wrapper {
  label {
    position: absolute;
    top: -10px;
    left: 0;
    margin: 0;
    transform-origin: 0 0;
    transform: translate3d(26px, 12px, 0);
    font-size: 16px;
    // font-weight: 500;
    transition: transform 0.2s, color 0.2s ease-in-out,
      background-color 0.2s ease-in-out;
    color: $gray-550;
    cursor: text;
    background-color: transparent;
    padding: 0px 1px;
  }

  svg.search-icon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: $gray-400;
  }

  svg.cancel-icon {
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translate(4px, -50%);
    color: $gray-600;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s ease 0.2s, transform 0.2s ease, opacity 0.2s ease;
  }

  input {
    appearance: none;
    background-color: $gray-150;
    padding: 12px 16px;
    border-radius: $border-radius-1;
    border: 1px solid $gray-300;
    font-size: 16px;
    height: 48px;

    width: 100%;
    height: 48px;
    outline: none !important;
    line-height: 1;
    font-weight: 600;
    color: $black-100;
    transition: all 0.2s ease;

    &::placeholder {
      color: transparent;
      user-select: none;
    }

    &:focus {
      border-color: $black-100;

      background-color: $gray-150;
    }
  }

  // input:focus + label {
  //   color: tint($primary, 50%);
  // }

  input:focus + label,
  input:not(:placeholder-shown) + label {
    background-color: $gray-150;
    transform: translate3d(12px, -8px, 0) scale(0.85);
  }

  input:not(:placeholder-shown) + label + svg.cancel-icon {
    visibility: visible;
    opacity: 1;
    transform: translate(-8px, -50%);
    transition: visibility 0s ease 0s, transform 0.2s ease, opacity 0.2s ease;
  }
}

.input__select {
  & > div {
    appearance: none;
    background-color: transparent;
    border-radius: $border-radius;
    border: 1px solid $gray-360;
    font-size: 16px;
    width: 100%;
    outline: none !important;
    line-height: 1;
    font-weight: 500;
    color: $gray-700;
    transition: all 0.2s ease;

    &::placeholder {
      color: transparent;
      user-select: none;
    }

    &:focus {
      background-color: $gray-150;
      border-color: $black-100;
      border: 1px solid $gray-360;
    }

    &:hover {
      border: 1px solid $gray-360;
    }

    [class*=" css-"][class*="-placeholder"] {
      color: $gray-550;
      font-weight: 500;
      font-size: 16px;
    }

    [class*=" css-"][class*="-Input"] {
      color: $black-100;
      font-weight: 500;
    }
  }
  input {
    height: unset !important;
  }
}

form {
  .input__wrapper {
    label {
      background: transparent;
      color: $gray-310;
      font-size: 18px;
    }

    &.auth {
      label {
        font-weight: 500;
        font-size: 18px;
      }
    }

    input {
      padding: 8px 16px;

      &.auth {
        padding: 0px 12px;
        height: 46px;
      }
    }

    input,
    select {
      appearance: none;
      background: transparent;
      border-radius: $border-radius;
      font-size: 18px;
      height: 40px;
      width: 100%;
      outline: none !important;
      line-height: 1;
      font-weight: 500;
      color: $black-100;
      border: 1px solid $gray-360;
      transition: all 0.2s ease, letter-spacing 0s;

      &.with-border {
        border: 1px solid $gray-360;
      }
      &::placeholder {
        letter-spacing: 0.01em;
        color: $gray-310;
        user-select: none;
      }

      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        font-family: 'placeholderFont', 'Helvetica-Neue-Regular', sans-serif;  
      }
      &::-moz-placeholder { /* Firefox 19+ */
        font-family: 'placeholderFont', 'Helvetica-Neue-Regular', sans-serif;  
      }
      &:-ms-input-placeholder { /* IE 10+ */
        font-family: 'placeholderFont', 'Helvetica-Neue-Regular', sans-serif;  
      }
      &:-moz-placeholder { /* Firefox 18- */
        font-family: 'placeholderFont', 'Helvetica-Neue-Regular', sans-serif;  
      }

      &:focus {
        border-color: $black;
      }
      &:hover {
        border-color: $black;
      }

      &:disabled {
        color: $gray-360;
        opacity: 0.9;
      }
      &.dark {
        color: $white;
        border-color: $gray-310;

        &:hover {
          border-color: $white;
        }

        &:focus {
          border-color: $white;
        }
  
      }
    }

    input[type="password"] {
      letter-spacing: 0.3rem;
    }

    input[name="password"] {
      padding-right: 48px;
    }

    .btn--show-password {
      position: absolute;
      top: 25%;
      right: 16px;
      cursor: pointer;

      svg {
        stroke: $gray-310;
        &:hover {
          stroke: $white;
        }
      }
    }


    // input:focus+label,
    // select:focus+label {
    //   color: tint($primary, 50%);
    // }

    input:focus + label,
    input:not(:placeholder-shown) + label,
    select:focus + label,
    select:valid + label {
      display: none;
      // transform: translate3d(18px, 13px, 0) scale(0.75) !important;
    }

    span.input-error {
      position: absolute;
      color: $danger;
      font-size: 12px;
      font-weight: 500;
      bottom: 0;
      left: 3px;
      display: inline-block;
      word-wrap: normal;
      width: 100%;
      height: 0px;
      line-height: 1;
      opacity: 0;
      transform: translateY(-10px);
      visibility: hidden;
      transition: visibility 0s, opacity 0.3s ease, transform 0.3s ease;

      &::first-letter {
        text-transform: uppercase;
      }
    }

    &.error {
      input,
      select {
        border-color: tint($danger, 75%);
      }

      input:focus + label,
      select:focus + label {
        color: tint($danger, 50%);
      }

      span.input-error {
        opacity: 1;
        transform: translateY(0px);
        visibility: visible;
      }
    }
  }
}

.otp-input {
  gap: 10px;

  input {
    width: 60px !important;
    margin: 0px !important;
    height: 60px !important;
    font-size: 30px !important;
    border: 1px solid #808080 !important;
    caret-color: #808080 !important;
    border-radius: 4px;
    background: transparent;
    outline: none;
    &:focus {
      border-color: $black-100 !important;
    }
    &::placeholder {
      color: $black-100;
      user-select: none;
    }
  }

  &.black {
    gap: 8.5px;

    input {
      width: 51.22px !important;
      height: 51.22px !important;
      color: $white;
      &:focus {
        border-color: $white !important;
      }
      &::placeholder {
        user-select: none;
      }
    }
  }
}

textarea {    
  appearance: none;
  outline: none !important;      
  transition: all 0.2s ease, letter-spacing 0s;
  background-color: transparent;
  border-radius: $border-radius;
  width: 100%;
  border: 1px solid $gray-360;
  padding: 8px 16px;
  resize: none;

  &::placeholder {
    font-size: 18px;
    letter-spacing: 0.01em;
    color: $gray-550;
    user-select: none;
  }

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-family: 'placeholderFont', 'Helvetica-Neue-Regular', sans-serif;  
  }
  &::-moz-placeholder { /* Firefox 19+ */
    font-family: 'placeholderFont', 'Helvetica-Neue-Regular', sans-serif;  
  }
  &:-ms-input-placeholder { /* IE 10+ */
    font-family: 'placeholderFont', 'Helvetica-Neue-Regular', sans-serif;  
  }
  &:-moz-placeholder { /* Firefox 18- */
    font-family: 'placeholderFont', 'Helvetica-Neue-Regular', sans-serif;  
  }

  &.popup {
    &:focus {
      border: 1px solid $black;
    }
  
    &:hover {
      border: 1px solid $black;
    }
  }

  &.dark {
    color: $white;
    &:focus {
      border-color: $white;
    }
  
    &:hover {
      border-color: $white;
    }
  
  }
}

.react-datepicker-wrapper {
  width: 100%
}

.react-select__control {
  background: red !important;
}