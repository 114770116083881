@font-face {
  font-family: "Helvetica-Neue-Regular";
  src: url("../fonts/HelveticaNeueRegular.ttf") format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica-Neue-Medium";
  src: url("../fonts/HelveticaNeueMedium.ttf") format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica-Neue-Bold";
  src: url("../fonts/HelveticaNeueBold.ttf") format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}


$enable-negative-margins: true;

$primary-font-family: Helvetica-Neue-Regular, sans-serif;

$header-height: 88px;
$sidebar-width: 300px;
$sidebarSmall-width: 130px;
$base-font-size: 16px;

$white: #fff !default;
$gray-50: #fbfbfb !default;
$gray-80: #f0f0f0 !default;
$gray-100: #f5f5f5 !default;
$gray-120: #f3f3f3 !default;
$gray-150: #f6f6f6 !default;
$gray-200: #eeeeee !default;
$gray-250: #B8B8B8 !default;
$gray-300: #e0e0e0 !default;
$gray-310: #B3B3B3 !default;
$gray-350: #d9d9d9 !default;
$gray-360: #A6A6A6 !default;
$gray-400: #bdbdbd !default;
$gray-500: #9e9e9e !default;
$gray-520: #c4c4c4 !default;
$gray-530: #808080 !default;
$gray-540: #c8c8c8 !default;
$gray-550: #a7a7a7 !default;
$gray-600: #757575 !default;
$gray-650: #d9d9d944 !default;
$gray-700: #616161 !default;
$gray-720: #7a7a7a !default;
$gray-750: #848486 !default;
$gray-800: #4f4f4f !default;
$gray-900: #262626 !default;

$black: #000 !default;
$black-100: #000000 !default;
$black-200: #121212 !default;
$black-300: #242424 !default;
$black-400: #3a3a3a !default;

$primary: #22a2ff !default;
$secondary: #000034 !default;
$success: #00b74a !default;
$info: #39c0ed !default;
$warning: #ffa900 !default;
$danger: #ff5449 !default;

$green-100: #27755e !default;
$green-150: #3DD143 !default;
$green-200: #00cc8f !default;
$green-300: #89e58c !default;
$green-400: #87d7bf !default;

$yellow-100: #FFF500 !default;

$primary-bg-color: $gray-200;
$primary-font-color: $gray-900;

$warning-1: #e5b15e !default;
$warning-2: #bd852b !default;

$danger-1: #ffb9b5 !default;
$danger-2: #f6827a !default;

$box-shadow-none: none !default;
$box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.07) !default;

$box-shadow-1: 0 0px 5px 0 rgba(0, 0, 0, 0.05) !default;
$box-shadow-2: 0 0px 10px 0 rgba(0, 0, 0, 0.05) !default;
$box-shadow-3: 0 0px 15px 0 rgba(0, 0, 0, 0.05) !default;
$box-shadow-4: 0 0px 20px 0 rgba(0, 0, 0, 0.05) !default;
$box-shadow-5: 0 0px 30px 0 rgba(0, 0, 0, 0.05) !default;
$box-shadow-6: 0 0px 40px 0 rgba(0, 0, 0, 0.05) !default;
$box-shadow-7: 0px 8px 0px $warning-1 !default;

$border-radius: 4px;
$border-radius-1: 5rem;
$border-radius-search-bar: 0.625rem;
$border-radius-layout: 0.5rem;
$border-radius-input: 1rem;
$border-radius-card: 1rem;

// Break points
$smallScreen: 576px;
$mediumScreen: 768px;
$largeScreen: 992px;
$xLargeScreen: 1200px;
$xxLargeScreen: 1400px;

$enable-cssgrid: true;
