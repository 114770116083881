.box-shadow-none {
  box-shadow: $box-shadow-none !important;
}
.box-shadow {
  box-shadow: $box-shadow !important;
}

.image-object-cover {
  object-fit: cover;
  object-position: center center;
}

.cursor-default {
  cursor: default !important;
}

.background-contain {
  background-size: cover;
  background-repeat: no-repeat;
  background-color: white;
  background-position: center;
}
.object-fit-contain {
  object-fit: contain;
  object-position: center;
}

// text truncate
.text-truncate-1 {
  @include line-clamp(1);
}
.text-truncate-2 {
  @include line-clamp(2);
}

// border-radius
.border-radius-90 {
  border-radius: $border-radius-1;
}

.border-radius-1 {
  border-radius: 16px 16px 16px 16px;
}

.border-radius-2 {
  border-radius: 13px 13px 13px 13px;
}

.border-radius-3 {
  border-radius: 8px;
}

// background-color
.background-color-1 {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(16, 10, 0, 0) 0.01%,
    #100a00 100%
  );
}
.background-color-2 {
  background-color: $black-300;
}

.text-warning-1 {
  color: $warning-1;
}

.text-gray-650 {
  color: $gray-650;
}

.text-gray-520 {
  color: $gray-520;
}
.text-gray-550 {
  color: $gray-550;
}
.text-gray-750 {
  color: $gray-750;
}
.text-gray-720 {
  color: $gray-720;
}
.text-gray-350 {
  color: $gray-350;
}
.text-gray-150 {
  color: $gray-150;
}
.text-black-100 {
  color: $black-100;
}
