#page {
  &--login,
  &--forgot-password {
    height: 100%;
    overflow-y: auto;

    .page--wrapper {
      @media (min-width: $xLargeScreen) {
        grid-template-columns: 660px auto;
      }

      .login {
        &_img {
          height: 100%;
          background-color: tint($primary, 75%);
          background-image: url(../images/login-img.png);
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
        }
      }
    }
  }

  &--account {
    .profile {
      .sb-avatar {
        margin: -120px auto 40px auto;
        box-shadow: $box-shadow-6;
      }
    }
    .profile-picture-edit {
      background-color: #121212;

      .profile-picture-edit-header {
        color: white;
      }
    }

  }
}

.profile {
  flex-direction: column;
  display: flex;

  .profile-image-text {
    font-weight: bold;
    font-size: small;
    color:#ccc;

    &:hover {
      cursor: pointer;
      color: #aaa;
    }
  }

  .profile-image-wrapper{
    height: 200px;
    width: 200px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
    background-repeat: no-repeat;
    background: url(https://via.placeholder.com/120);
  }

  .profile-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .user-info-heading {
    font-size:  50px;
    font-weight: 700;
    color: $white;
    margin-block: 15px;
  }

  .username-text {
    font-size: 20px;
    color: $gray-250;
  }

  .profile-btn {
    background: $white;
    color: $black;
    border-radius: 70px;
    padding: 8px 16px;
    font-size: 16px;
    outline: none;
    height: 28px;
    display: flex;
    align-items: center;

    &.outlined {
      border: 1px solid $white;
      background: transparent;
      color: $white;
    }
  }

  .profile-info-text {
    font-size: 20px;
    font-weight: 400;
    color: $white;

    & font {
      font-weight: 700;
      margin-right: 4px;
    }
  }

  .bio-wrapper {
    width: 450px;
    height: 30px;
    font-size: 20px;
    color: $gray-250;
    text-align: center;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.auth-pages-wrapper {
  height: 100vh;
  width: 100%;
  // background-image: url("../images/cheeky-auth-background.png");
  // background-color: #FFF500;
  background: $black-200;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: grid;

  .auth-block {
    height: 100%;
    width: 100%;
    // width: 720px;
    background: $black-200;
    // border-radius: 15px 0px 0px 15px;
    justify-self: flex-end;
    align-items: center;
    padding: 100px 0px 50px 0px;

  }

  .content-wrapper {
    height: inherit;
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;    
    overflow-y: auto;
    padding-bottom: 50px;
    overflow-x: hidden;

    &.sign-up {
      width: 390px;
      padding-inline: 20px;
    }
  }

  .logo-wrapper {
    margin-bottom: 20px;
  }

  .header-text {
    font-family: "Helvetica-Neue-Bold";
    font-weight: 700;
    color: $white;
    font-size: 50px;
    max-width: 390px;
    text-align: center;
    line-height: 61px;
    margin-bottom: 45px;
  }

  .auth-form-info-message {
    color: $gray-310;
    text-align: center;
  }

  a, font {
    text-decoration: underline;
    color: $white;
    cursor: pointer;
  }

  .react-datepicker-wrapper {
    margin-top: 6px;
  }

  .progress-indicator-wrapper {
    margin-top: 30px;
    margin-bottom: 65px;
  }

  .registration-progress-indicator {
    height: 14px;
    border-radius: 5px;
    width: 100%;
    background: $gray-310;

    &.active {
      background: $white;
    }
  }

  .restore-email-text {
    font-size: 20px;
    font-weight: 400;
    color: $gray-530;
    text-align: center;
    margin-bottom: 30px;
  
    span{
      color: $white;
      margin-left: 4px;
    }
  
    font {
      color: $white;
      margin-left: 4px;
      font-weight: 600;
    }
  }

  .avatar-container {
    margin-top: 30px;
    margin-bottom: 10px;
    position: relative;
    height: 140px;
    width: 140px;
    border-radius: 50%;
    background: $gray-350;
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    align-self: center;

    svg {
      position: absolute;
      bottom: 10px;
    }

    .avatar-image {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  .forgot-password-text {
    font-size: 16px;
    text-decoration: underline;
    font-weight: 600;
    color: $white;
    cursor: pointer;
  }

  .login-form-wrapper {
    max-width: 410px;
  }

  .field-desc-text {
    font-size: 16px;
    color: $white;
    font-family: 'Helvetica-Neue-Medium';
  }

  .university-search {
    margin-top: 10px; 
    color: $white;
    font-family: 'Helvetica-Neue-Medium';
  }
  
  .search-input {
    width: 100%;
    padding: 12px; 
    font-size: 16px;
    line-height: 1.5; 
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #121212; 
    color: #fff;
  }
  
  .university-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 200px;
    overflow-y: auto;
    background-color: #121212; 
    border: 1px solid #ccc;
    border-radius: 0 0 4px 4px;
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 10;
  }
  
  .university-item {
    padding: 12px;
    cursor: pointer;
    color: #fff;
    font-size: 16px;
  }
  
  .university-item:hover {
    background-color: #333;
  }
  
  .loading {
    color: #fff;
    padding: 12px;
    font-size: 14px;
  }
  .account-text {
    margin-top: 45px;
    color: $gray-360;
    font-size: 16px;
    a {
      text-decoration: underline;
      color: $white;
      font-weight: 600;
    }
  }

  .account-text-margin-low {
    margin-top: 15px;
  }

  .divider-wrapper {
    max-width: 350px;
    width: 350px;
    margin-block: 45px;

    .divider-line {
      height: 2px;
      background: $gray-360;
      width: 100%;
    }
  }

  .social-login-btn {
    height: 48px;
    display: flex;
    align-items: center;
    padding: 0px 32px;
    gap: 48px;
    border: 2px solid $white;
    background: transparent;
    border-radius: 70px;
    color: $white;
    font-weight: 700;
    font-size: 16px;
  }

  .my-facebook-button-class {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    padding: 0px 32px;
    gap: 48px;
    border: 2px solid $white;
    background: transparent;
    border-radius: 70px;
    color: $white;
    font-weight: 700;
    font-size: 16px;
  }
}


  .search-tags-wrapper {
    display: flex;
    gap: 15px;
    margin-bottom: 50px;
  }

  .search-tag {
    border: 1px solid $white;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    color: $white;
    border-radius: 70px;
    font-weight: 500;
    height: 32px;
    cursor: pointer;

    &.active {
      background: $white;
      color: $black;
    }
  }

  .categories-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .category-controls {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;
  }

  .search-category-heading {
    font-weight: 700;
    font-size: 30px;
    color: $white;
  }

  .show-all-btn {
    font-size: 16px;
    font-weight: 500;
    color: $gray-310;
    cursor: pointer;
  }

